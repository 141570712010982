import React, { useState, useEffect, useLayoutEffect } from "react";
import { Container, Grid, Paper, Box } from "@material-ui/core";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

function PaperBox(props) {
  const prop = props.props;
  // console.log("props==>", prop);

  return (
    <>
      <>
        {(prop.granted == true) ? (
          <Paper className="paper permPaper">
            <span className="paperBox">
              <span className="papermark paperAllow">
                <i
                  class="fa fa-check"
                  aria-hidden="true"
                  style={{ color: "green" }}
                ></i>
              </span>
              <span className="papername">{prop?.name}</span>
            </span>
          </Paper>
        ) : (
          <Paper className="paper noPermPaper">
            <span className="paperBox">
              <span className="papermark paperDenined">
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                  style={{ color: "red" }}
                ></i>
              </span>
              <span className="papername">{prop?.name}</span>
            </span>
          </Paper>
        )}
      </>
    </>
  );
}

export default PaperBox;
