import React, { useState, useEffect } from "react";
import { Container, Grid, Box } from "@material-ui/core";
import { Card, CardBody } from "reactstrap";
import PaperBox from "../../../components/PaperBox/PaperBox";
import Layout from "../../../components/Layout/Layout";

function AdminCompliance(props) {
  const userId = window.localStorage.getItem("adminkey");
  const storedDetails = window.localStorage.getItem("AdminClientDetails");
  const changedDetails = window.localStorage.getItem("AdminClientFold");
  const [status, setStatus] = useState(false);
  const [isthere, setIsthere] = useState(false);
  const [allPerms, setAllPerms] = useState([
    { name: "Direct Tax", granted: false },
    { name: "Indirect Tax", granted: false },
    { name: "Accounting", granted: false },
    { name: "Corporate", granted: false },
    { name: "Others", granted: false },
  ]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (storedDetails) {
      const tlpermor = JSON.parse(storedDetails);
      if (tlpermor?.compliance_module === "1") {
        setAllPerms((prevPerms) =>
          prevPerms.map((perm) => {
            switch (perm.name) {
              case "Direct Tax":
                return { ...perm, granted: tlpermor.direct_tax === "1" };
              case "Indirect Tax":
                return { ...perm, granted: tlpermor.indirect_tax === "1" };
              case "Accounting":
                return { ...perm, granted: tlpermor.accounting === "1" };
              case "Corporate":
                return { ...perm, granted: tlpermor.corporate === "1" };
              case "Others":
                return { ...perm, granted: tlpermor.other === "1" };
              default:
                return perm;
            }
          })
        );
      } else {
        setAllPerms([
          { name: "Direct Tax", granted: false },
          { name: "Indirect Tax", granted: false },
          { name: "Accounting", granted: false },
          { name: "Corporate", granted: false },
          { name: "Others", granted: false },
        ]);
      }
    }
  }, []);

  useEffect(() => {
    if (storedDetails) {
      const details = JSON.parse(storedDetails);
      // console.log("storedDetails==>", details);
      setIsthere(true);
      setStatus(details.compliance_module === "1");
    }
  }, [storedDetails]);

  useEffect(() => {
    if (changedDetails) {
      // console.log("changedDetails==>", changedDetails);
    }
  }, [changedDetails]);

  return (
    <>
      <Layout AdmincompDashboard="AdmincompDashboard" adminUserId={userId}>
        <Container
          maxWidth="xl"
          style={{ margin: "10px 0px 0px 0px", padding: "0px 0px 0px 0px" }}
        >
          <Card>
            <CardBody style={{ minHeight: "700px" }}>
              {loading && (
                <Grid
                  item
                  sm={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100vh"
                  style={{ margin: "auto" }}
                >
                  <span className="HeadingSpan">
                    <h1>Client Data Management Service</h1>
                    {isthere ? (
                      <div
                        className={status ? "status-active" : "status-inactive"}
                      >
                        {status ? "Active" : "Inactive"}
                      </div>
                    ) : (
                      ""
                    )}
                  </span>

                  <Box m={1} mt={5}>
                    <span className="PaperBoat">
                      {allPerms.map((item, index) => (
                        <PaperBox props={item} />
                      ))}
                    </span>
                  </Box>
                </Grid>
              )}
            </CardBody>
          </Card>
        </Container>
      </Layout>
    </>
  );
}

export default AdminCompliance;
