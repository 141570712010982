import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Card, CardBody,} from "reactstrap";
import Layout from "../../../components/Layout/Layout";
import PaperBox from "../../../components/PaperBox/PaperBox";

function TlCompliance(props) {
  let history = useHistory();
  const userid = window.localStorage.getItem("tlkey");
  const [loading, setLoading] = useState(true);
  const [allPerms, setAllPerms] = useState([
    { name: "Direct Tax", granted: false },
    { name: "Indirect Tax", granted: false },
    { name: "Accounting", granted: false },
    { name: "Corporate", granted: false },
    { name: "Others", granted: false },
  ]);
  const tlpermor = window.localStorage.getItem("TlClientPerm");

  useEffect(() => {
    if (tlpermor) {
      const tlperm = JSON?.parse(tlpermor);
      setAllPerms((prevPerms) =>
        prevPerms.map((perm) => {
          switch (perm.name) {
            case "Direct Tax":
              return { ...perm, granted: tlperm.direct_tax === "1" };
            case "Indirect Tax":
              return { ...perm, granted: tlperm.indirect_tax === "1" };
            case "Accounting":
              return { ...perm, granted: tlperm.accounting === "1" };
            case "Corporate":
              return { ...perm, granted: tlperm.corporate === "1" };
            case "Others":
              return { ...perm, granted: tlperm.other === "1" };
            default:
              return perm;
          }
        })
      );
    }
  }, []);

  return (
    <>
      <Layout TlcompDashboard="TlcompDashboard" TLuserId={userid}>
        <Container
          maxWidth="xl"
          style={{ margin: "10px 0px 0px 0px", padding: "0px 0px 0px 0px" }}
        >
          <Card>
            <CardBody style={{ minHeight: "700px" }}>
              {loading && (
                <Grid
                  item
                  sm={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100vh"
                  style={{ margin: "auto" }}
                >
                  <span className="HeadingSpan">
                    <h1>Client Data Management Service</h1>
                  </span>
                  <Box m={1} mt={5}>
                    <span className="PaperBoat">
                  {allPerms.map((item, index) => (
                    <PaperBox props={item} />
                  ))}
                  </span>
                  </Box>
                </Grid>
              )}
              <span className="noticespan">
                <span style={{ color: "red" }}></span>For access to various
                services,please contact{" "}
                <span style={{ color: "red" }}>admin</span>
              </span>
            </CardBody>
          </Card>
        </Container>
      </Layout>
    </>
  );
}

export default TlCompliance;
