import React, { useState, useEffect, useRef } from "react";
import Layout from "../Layout/Layout";
import axios from "axios";
import { baseUrl } from "../../config/config";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import { Select } from "antd";
import BootstrapTable from "react-bootstrap-table-next";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { makeStyles } from "@material-ui/core/styles";

// import { FileIcon } from "../../../components/Common/MessageIcon";
import FolderIcon from "@mui/icons-material/Folder";
// import RightClickComponent from "../../../components/RightClick/RightClick";
// import RightClickComponent from "../../../components/RightClick/RightClickOne";

const useStyles = makeStyles((theme) => ({
  isActive: {
    backgroundColor: "green",
    color: "#fff",
    margin: "0px 10px",
  },
}));
function ClientSelect(props) {
  let name = props["props"];
  // console.log("name", name);
  const classes = useStyles();
  let history = useHistory();
  const { Option } = Select;
  const [clientId, setClientId] = useState("");
  const [ondashboard, setOndashboard] = useState(null);
  const [clientAccess, setClientAccess] = useState("");
  const [selectStyle, setSelectStyle] = useState({});
  const userid = window.localStorage.getItem("tlkey");
  const tpuserid = window.localStorage.getItem("tpkey");
  const clientid = window.localStorage.getItem("TlCompClientId");
  const tpclientid = window.localStorage.getItem("TpCompClientId");
  // const [clientShowData, setClientShowData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const type = [
    "direct_tax",
    "indirect_tax",
    "corporate",
    "accounting",
    "other",
  ];
  // const [ViewDiscussion, setViewDiscussion] = useState(false);
  // const [viewProposalModal, setViewProposalModal] = useState(false);
  // const [scrolledTo, setScrolledTo] = useState("");
  // const [lastDown, setLastDown] = useState("");
  // const myRefs = useRef([]);
  // const myRef = useRef([]);

  // console.log("name==>", name);

  const token = window.localStorage.getItem("tlToken");
  const ClientListing = JSON.parse(window.localStorage.getItem("TlClientList"));
  const tpClientListing = JSON.parse(
    window.localStorage.getItem("TpClientList")
  );
  const AdminClientListing = JSON.parse(
    window.localStorage.getItem("AdminClientList")
  );
  const AdminClientID = JSON.parse(
    window.localStorage.getItem("AdminClientID")
  );
  const AdminClientFold = JSON.parse(
    window.localStorage.getItem("AdminClientFold")
  );
  const myConfig = {
    headers: {
      uit: token,
    },
  };

  useEffect(() => {
    // console.log("clientList==>", clientList);
  }, [clientList]);

  // useEffect(() => {
  //   var element = document.getElementById(scrolledTo);
  //   if (element) {
  //     let runTo = myRef.current[scrolledTo];
  //     runTo?.scrollIntoView(false);
  //     runTo?.scrollIntoView({ block: "center" });
  //   }
  // }, [ViewDiscussion]);
  // useEffect(() => {
  //   let runTo = myRefs.current[lastDown];
  //   runTo?.scrollIntoView(false);
  //   runTo?.scrollIntoView({ block: "center" });
  // }, [viewProposalModal]);

  useEffect(() => {
    if (name == "Team Leader") {
      if (!ClientListing) {
        getTlinfo();
      } else {
        // console.log("ClientListing...", ClientListing);
        setClientList(ClientListing);
      }
      if (clientid) {
        // console.log("clientid", clientid);
        setClientId(clientid);
      }
    } else if (name == "admin") {
      // console.log("ClientListing==>", ClientListing);
      if (!AdminClientListing) {
        getAdmininfo();
      } else {
        // console.log("AdminClientListing...", AdminClientListing);
        setClientList(AdminClientListing);
        if (AdminClientID) {
          setClientId(AdminClientID);
        }
      }
      if (AdminClientID) {
        setClientId(AdminClientID);
      }
    } else if (name == "Tax Professional") {
      if (!tpClientListing || tpClientListing?.length == 0) {
        getTlinfo();
      } else {
        // console.log("ClientListing...", ClientListing);
        setClientList(tpClientListing);
      }
      if (tpclientid) {
        // console.log("clientid", tpclientid);
        setClientId(tpclientid);
      }
    }
    const url = window.location.href;
    if (url.includes("/compliance")) {
      setOndashboard(false);
    } else {
      setOndashboard(true);
    }
  }, []);

  const getAdmininfo = () => {
    const token = window.localStorage.getItem("adminToken");
    const myConfig = {
      headers: {
        uit: token,
      },
    };
    axios
      .get(`${baseUrl}/complianceadmin/getallclient`, myConfig)
      .then((res) => {
        // console.log("gettingTLaccess=>", res.data.result);
        let allac = res.data.result;
        setClientList(allac);
        localStorage.setItem("AdminClientList", JSON.stringify(allac));
      });
  };

  const getTlinfo = () => {
    if (name == "Tax Professional") {
      const token = window.localStorage.getItem("tptoken");
      const myConfig = {
        headers: {
          uit: token,
        },
      };
      axios
        .get(
          `${baseUrl}/tl/tlcompliancelist?tp_id=${JSON.parse(tpuserid)}`,
          myConfig
        )
        .then((res) => {
          // console.log("gettingTLaccess=>", res.data.result);
          let allac = res.data.result;
          setClientList(allac);
          localStorage.setItem("TpClientList", JSON.stringify(allac));
        });
    } else {
      const token = window.localStorage.getItem("tlToken");
      const myConfig = {
        headers: {
          uit: token,
        },
      };
      axios
        .get(
          `${baseUrl}/tl/tlcompliancelist?tl_id=${JSON.parse(userid)}`,
          myConfig
        )
        .then((res) => {
          // console.log("gettingTLaccess=>", res.data.result);
          let allac = res.data.result;
          setClientList(allac);
          localStorage.setItem("TlClientList", JSON.stringify(allac));
        });
    }
  };

  const handleClient = (value) => {
    // console.log("value==>", value);
    if (name == "Team Leader") {
      // console.log("=>IN..TP");
      if (value?.length === 0) {
        localStorage.removeItem("TlCompClientId");
        localStorage.removeItem("TlClientPerm");
        localStorage.removeItem("TlClientAccess");
        localStorage.removeItem("TlClientList");
        history.push("/teamleader/compliance");
      }
      if (value) {
        localStorage.setItem("TlCompClientId", value);
        const filteredArray = clientList.filter(
          (item) => item.client_id === value
        );
        const toSave = filteredArray[0];
        if (toSave) {
          localStorage.setItem("TlClientPerm", JSON.stringify(toSave));
          history.push("/teamleader/compliance");
        }
      }
      setClientId(value);
      getClientInfo(value);
    }
    // else if (name == "admin") {
    //   console.log("=>IN..AD", value);
    //   if (value?.length === 0) {
    //     localStorage.removeItem("AdminClientPerm");
    //     history.push("/admin/compliance");
    //   }
    //   if (value) {
    //     localStorage.setItem("AdminClientID", JSON.stringify(value));
    //     setClientId(value);
    //     console.log("clientList==>", value, clientList);
    //     const filteredArray = clientList.filter((item) => item.id === value);
    //     const selectedClient = filteredArray[0];
    //     localStorage.setItem(
    //       "AdminClientDetails",
    //       JSON.stringify(selectedClient)
    //     );
    //     console.log("selectedClient==>", selectedClient);
    //     const folder_string = selectedClient["folder_ids"];
    //     const folder_ids = folder_string.split(",");
    //     const folder_names = [
    //       "direct_tax",
    //       "indirect_tax",
    //       "corporate",
    //       "accounting",
    //       "other",
    //     ];
    //     console.log("selectedClient=>", folder_ids);
    //     const folderDetail = folder_names.reduce((acc, name, index) => {
    //       acc[name] = Number(folder_ids[index]);
    //       return acc;
    //     }, {});
    //     console.log("folderDetails==>", folderDetail);
    //     const toSave = folderDetail;
    //     if (toSave) {
    //       localStorage.setItem("AdminClientPerm", JSON.stringify(toSave));
    //       history.push("/admin/compliance");
    //     }
    //   }
    // }
    else if (name == "Tax Professional") {
      console.log("=>IN..TP");
      if (value?.length === 0) {
        localStorage.removeItem("TpCompClientId");
        localStorage.removeItem("TpClientPerm");
        localStorage.removeItem("TpClientAccess");
        localStorage.removeItem("TpClientList");
        history.push("/taxprofessional/compliance");
      }
      if (value) {
        localStorage.setItem("TpCompClientId", value);
        const filteredArray = clientList.filter(
          (item) => item.client_id === value
        );
        const toSave = filteredArray[0];
        if (toSave) {
          localStorage.setItem("TpClientPerm", JSON.stringify(toSave));
          history.push("/taxprofessional/compliance");
        }
      }
      setClientId(value);
      getClientInfo(value);
    }
  };

  const handleAdminClient = (value) => {
    if (value?.length === 0) {
      localStorage.removeItem("AdminClientPerm");
      history.push("/admin/compliance");
    } else {
      localStorage.setItem("AdminClientID", JSON.stringify(value));
      setClientId(value);
      const filteredArray = clientList.filter((item) => item.id === value);
      const selectedClient = filteredArray[0];
      localStorage.setItem(
        "AdminClientDetails",
        JSON.stringify(selectedClient)
      );
      const folder_string = selectedClient["folder_ids"];
      const folder_ids = folder_string.split(",");
      const folder_names = [
        "direct_tax",
        "indirect_tax",
        "corporate",
        "accounting",
        "other",
      ];
      const token = window.localStorage.getItem("adminToken");
      const myConfig = {
        headers: {
          uit: token,
        },
      };
      axios
        .get(
          `${baseUrl}/complianceadmin/getfolderids?client_id=${value}`,
          myConfig
        )
        .then((res) => {
          let value = res.data.result;
          localStorage.setItem("AdminClientFold", JSON.stringify(value));
          // console.log("ADMINhere..")
          history.push("/admin/compliance")
        });
    }
  };

  useEffect(() => {
    // console.log("clientId=>", clientId);
  }, [clientId]);

  const getClientInfo = (id) => {
    if (name == "Tax Professional") {
      const token = window.localStorage.getItem("tptoken");
      const myConfig = {
        headers: {
          uit: token,
        },
      };
      axios
        .get(`${baseUrl}/tl/rootfolderlist?client_id=${id}`, myConfig)
        .then((res) => {
          let access = res.data.result;
          if (name == "Tax Professional") {
            localStorage.setItem("TpClientAccess", JSON.stringify(access));
          } else {
            localStorage.setItem("TlClientAccess", JSON.stringify(access));
          }
          setClientAccess(access);
        });
    } else {
      axios
        .get(`${baseUrl}/tl/rootfolderlist?client_id=${id}`, myConfig)
        .then((res) => {
          let access = res.data.result;
          if (name == "Tax Professional") {
            localStorage.setItem("TpClientAccess", JSON.stringify(access));
          } else {
            localStorage.setItem("TlClientAccess", JSON.stringify(access));
          }
          setClientAccess(access);
        });
    }
  };

  const getDropdownClass = () => {
    if (!clientId) return "";
    const client = clientList.find((client) => client.id === clientId);
    return client?.compliance_module === "1" ? "green-bg" : "red-bg";
  };

  return (
    <>
      <span>
        {(name == "Team Leader" || name == "Tax Professional") && (
          <Select
            style={{ width: 250 }}
            placeholder="Select client"
            name="clientId"
            defaultValue={""}
            value={clientId}
            onChange={handleClient}
            disabled={ondashboard}
          >
            <Option value={""}>--Select Client--</Option>
            {clientList && clientList.length > 0 ? (
              clientList?.map((p, index) => (
                <Option value={p.client_id} key={index}>
                  {p.user_id}
                </Option>
              ))
            ) : (
              <Option></Option>
            )}
          </Select>
        )}

        {name == "admin" && (
          <Select
            placeholder="Select client"
            name="clientId"
            defaultValue={""}
            value={clientId}
            onChange={handleAdminClient}
            disabled={ondashboard}
            style={{
              width: 250,
            }}
            className={getDropdownClass()}
          >
            <Option value={""}>--Select Client--</Option>
            {clientList && clientList.length > 0 ? (
              clientList?.map((p, index) => (
                <Option
                  value={p.id}
                  key={index}
                  style={{
                    color: p?.compliance_module === "1" ? "green" : "red",
                  }}
                  className={
                    p?.compliance_module === "1"
                      ? "adminSelect greenLine"
                      : "adminSelect redLine"
                  }
                >
                  {p.user_id}
                </Option>
              ))
            ) : (
              <Option></Option>
            )}
          </Select>
        )}
      </span>
    </>
  );
}

export default ClientSelect;
