import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Collapse,
} from "reactstrap";
import { Select } from "antd";
import { Spinner } from "reactstrap";
import axios from "axios";
import { baseUrl, baseUrl2 } from "../../../config/config";
import Swal from "sweetalert2";
import { startCase } from "lodash";

function CustDetails({ custId, toggle, modal, changecomp }) {
  const { Option } = Select;
  const token = window.localStorage.getItem("adminToken");
  const myConfig = {
    headers: {
      uit: token,
    },
  };
  const int = [
    {
      id: "1",
      value: "Direct Tax",
      name: "direct_tax",
      status: false,
    },
    {
      id: "2",
      value: "Indirect Tax",
      name: "indirect_tax",
      status: false,
    },
    {
      id: "3",
      value: "Corporate",
      name: "corporate",
      status: false,
    },
    {
      id: "4",
      value: "Accounting",
      name: "accounting",
      status: false,
    },
    { id: "5", value: "Others", name: "other", status: false },
  ];

  const [count, setCount] = useState("");
  const [sub, setSub] = useState([]);
  const [primecrif, setPrimecrif] = useState(false);
  const [secCrif, setSecCrif] = useState([]);
  const [permOption, setPermOption] = useState([]);
  const [stats, setStats] = useState(false);
  const [showSubCat, setShowSubCat] = useState([]);
  const { handleSubmit, register, errors, reset } = useForm();
  const [disable, setDisable] = useState(false);
  const [allSub, setAllsub] = useState([]);
  const [primeSub, setPrimeSub] = useState(int);
  const [trigger,setTrigger]=useState(false);
  const [savePrime, setSavePrime] = useState([]);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [secLoad, setSecLoad] = useState(false);

  const selectInputRef = useRef();
  var subPerval;

  const options2 = permOption.map((v) => ({
    value: v.id,
    label: v.details,
  }));

  //change date format
  function ChangeFormateDate(oldDate) {
    if (oldDate == null) {
      return null;
    }
    return oldDate.toString().split("-").reverse().join("-");
  }
  const clientEnable = (e, value) => {
    let formData = new FormData();
    if (e.target.checked === true) {
      formData.append("status", "1");
    } else {
      formData.append("status", "0");
    }

    // formData.append("id", value);

    // axios({
    //   method: "POST",
    //   url: `${baseUrl}/admin/clientstatus`,
    //   headers: {
    //     uit: token,
    //   },
    //   data: formData,
    // }).then((res) => {
    //   if (res.data.code === 1) {
    //     // getCustomer();
    //   } else {
    //     // getCustomer();
    //     Swal.fire({
    //       title: "error",
    //       html: "Something went wrong, please try again",
    //       icon: "error",
    //     });
    //   }
    // });
  };

  const getClientData = () => {
    axios
      .get(`${baseUrl}/admin/getAllList?id=${custId}`, myConfig)
      .then((res) => {
        // console.log("getting=>", res.data.result);
        let result = res.data.result;
        setHistory(result[0]);
      });
  };

  function splitString(str) {
    const substrings = str?.split(",");
    return substrings;
  }

  useEffect(() => {
    setAllsub(int);
  }, []);
  useEffect(() => {
    // console.log('iid',custId);
    getClientData();
  }, [custId]);

  useEffect(() => {
    if (modal == false) {
      setPrimeSub(int);
    }
  }, [modal]);

  useEffect(() => {
    // console.log('custId, toggle, modal, changecomp ',custId, toggle, modal, changecomp );
  }, [custId, toggle, modal, changecomp, history]);

  useEffect(() => {
    console.log("primeSub==>", primeSub);
  }, [primeSub]);

  useEffect(() => {
    if (history) {
      if (Number(history.compliance_module) === 1) {
        setStats(true);
      } else {
        setStats(false);
      }
      getinfo();
      getPrime(history?.id);
      setSub(splitString(history.secondary_email));
    }
  }, [history]);

  useEffect(() => {
    if (modal == true) {
      getPrime(history?.id);
    }
  }, [modal, history]);

  const getinfo = () => {
    axios
      .get(`${baseUrl}/admin/subcustomer?client_id=${custId}`, myConfig)
      .then((res) => {
        // console.log("getting=>", res.data.result);
        let allSub = res.data.result;
        allSub.forEach((obj) => {
          obj.status = false;
        });
        setSecCrif(allSub);
        let perm = res.data.result;
      });
  };

  // useEffect(() => {
  //   if (modal == true) {
  //     handlePrime();
  //   }
  // }, [modal, stats]);

  useEffect(() => {
    // console.log("secCrif=>>", secCrif);
  }, [secCrif]);

  const showsub = (e) => {
    // console.log("e=>", e);
    if (stats === true) {
      const fo = secCrif.find((item) => item.id === e);
      setSecCrif((prevState) =>
        prevState.map((item) => {
          if (item.id === e) {
            return { ...item, status: true };
          }
          return { ...item, status: false };
        })
      );

      setAllsub((prevState) =>
        prevState.map((item) => {
          if (item.name === "direct_tax") {
            if (fo?.direct_tax == 1) {
              return { ...item, status: true };
            } else {
              return { ...item, status: false };
            }
          } else if (item.name === "indirect_tax") {
            if (fo?.indirect_tax == 1) {
              return { ...item, status: true };
            } else {
              return { ...item, status: false };
            }
          } else if (item.name === "accounting") {
            if (fo?.accounting == 1) {
              return { ...item, status: true };
            } else {
              return { ...item, status: false };
            }
          } else if (item.name === "corporate") {
            if (fo?.corporate == 1) {
              return { ...item, status: true };
            } else {
              return { ...item, status: false };
            }
          } else {
            if (fo?.other == 1) {
              return { ...item, status: true };
            } else {
              return { ...item, status: false };
            }
          }
        })
      );
    } else {
    }
  };

  const hidesub = (e) => {
    setSecCrif((prevState) =>
      prevState.map((item) => {
        if (item.id === e) {
          return { ...item, status: false };
        }
        return item;
      })
    );
  };

  const handleSubCategory = (e) => {
    e.stopPropagation();
    const { name, checked } = e.target;
    let allId = [];
    setAllsub((prevState) =>
      prevState.map((item) => {
        if (item.name === name) {
          return { ...item, status: checked };
        }
        return item;
      })
    );
  };

  const crifperm = (e, f) => {
    setStats(!stats);
  };

  const handlePrimeSubCategory = (e) => {
    e.stopPropagation();
    const { name, checked } = e.target;
    let allId = [];
    const updatedSecCrif = secCrif.map((item) => ({
      ...item,
      status: false,
    }));
    setSecCrif(updatedSecCrif);
    setPrimeSub((prevState) =>
      prevState.map((item) => {
        if (item.name === name) {
          return { ...item, status: checked };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    // console.log("primeSub=>", primeSub);
  }, [primeSub]);

  useEffect(() => {
    // console.log("allSub=>", allSub);
  }, [allSub]);

  const onSubmit = (value) => {
    const submitvalue = value;
    Swal.fire({
      title: "Are you sure?",
      // text: "Want to change client complicance status? Yes, change it!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed == true) {
        setSecLoad(true);
        // console.log("value", submitvalue);
        let formData = new FormData();
        if (value?.id?.length > 0) {
          formData.append("id", Number(submitvalue?.id));
        } else {
          formData.append("id", null);
        }

        formData.append("client_id", history.id);
        formData.append("subclient_id", submitvalue.subclient_id);

        const addSub = (e) => {
          const foundObject = allSub.find((item) => item.name === e);
          if (foundObject) {
            const subStatus = foundObject.status;
            if (subStatus == true) {
              return 1;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        };
        // const direct = allSub.filter(item => item.name === "direct_tax");

        formData.append("direct_tax", addSub("direct_tax"));
        formData.append("indirect_tax", addSub("indirect_tax"));
        formData.append("corporate", addSub("corporate"));
        formData.append("accounting", addSub("accounting"));
        formData.append("other", addSub("other"));

        axios
          .post(`${baseUrl2}/v1/admin/updatesubcustomer`, formData, {
            headers: {
              "content-type": "multipart/form-data",
              uit: token,
            },
          })
          .then((res) => {
            if (res.data.code === 1) {
              Swal.fire({
                title: "success",
                html: "Compliance Permission changed successfully",
                icon: "success",
              });
              setSecLoad(false);
              setSecCrif((prevState) =>
                prevState.map((item) => {
                  if (item.id === value.subclient_id) {
                    return {
                      ...item,
                      direct_tax: addSub("direct_tax"),
                      indirect_tax: addSub("indirect_tax"),
                      corporate: addSub("corporate"),
                      accounting: addSub("accounting"),
                      other: addSub("other"),
                      status: false,
                    };
                  } else {
                    return {
                      ...item,
                      status: false,
                    };
                  }
                })
              );
              // setStats(true);
            } else if (res.data.code === 0) {
              setSecLoad(false);
              // Swal.fire({
              //   title: "success",
              //   html: "Compliance status changed successfully",
              //   icon: "success",
              // });
              // setStats(false);
            } else {
            }
          });
      }
    });
  };

  const updateStatus = (result, primesub) => {
    const clientData = result[0];
    return primesub.map((item) => ({
      ...item,
      status: clientData[item.name] === "1",
    }));
  };

  const getPrime = (id) => {
    axios
      .get(`${baseUrl}/admin/maincustomer?client_id=${id}`, myConfig)
      .then((res) => {
        let result = res.data.result;
        console.log("gettingPRIME==>", result);
        setPrimeSub(updateStatus(result, primeSub));
        setTrigger(true)
        setSavePrime(updateStatus(result, primeSub));
      });
  };


  useEffect(() => {
    if (stats === false) {
      setPrimeSub(int);
    }else{
      setPrimeSub(savePrime)
    }
  }, [stats]);

  const primeSubmit = () => {
    Swal.fire({
      title: "Are you sure?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed == true) {
        let formData = new FormData();
        setLoading(true);
        const addSub = (e) => {
          const foundObject = primeSub.find((item) => item.name === e);
          if (foundObject) {
            const subStatus = foundObject.status;
            if (subStatus == true) {
              return 1;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        };

        if (stats === true) {
          let perm = 1;
          formData.append("complianceservice", perm);
        } else {
          let perm = 0;
          formData.append("complianceservice", perm);
        }
        console.log(
          `clientID=${history.id}`,
          `compliance_module=${history.compliance_module}`,
          `direct_tax=${addSub("direct_tax")}`,
          `indirect_tax=${addSub("indirect_tax")}`,
          `corporate=${addSub("corporate")}`,
          `accounting=${addSub("accounting")}`,
          `other=${addSub("other")}`
        );
        formData.append("id", history.id);
        formData.append("direct_tax", addSub("direct_tax"));
        formData.append("indirect_tax", addSub("indirect_tax"));
        formData.append("corporate", addSub("corporate"));
        formData.append("accounting", addSub("accounting"));
        formData.append("other", addSub("other"));

        axios
          .post(`${baseUrl2}/v1/admin/complianceservice`, formData, {
            headers: {
              "content-type": "multipart/form-data",
              uit: token,
            },
          })
          .then((res) => {
            if (res.data.code === 1) {
              Swal.fire({
                title: "success",
                html: "Compliance Permission changed successfully",
                icon: "success",
              });
              setSavePrime(primeSub);
              const updatedSecCrif = secCrif.map((item) => ({
                ...item,
                status: false,
              }));
              setSecCrif(updatedSecCrif);
              getinfo();
              setLoading(false);
            } else if (res.data.code === 0) {
              // Swal.fire({
              //   title: "success",
              //   html: "Compliance status changed successfully",
              //   icon: "success",
              // });
              // setStats(false);
            } else {
            }
          });
      }
    });
  };

  return (
    <div>
      <Modal isOpen={modal} fade={false} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>
          <span className="ComplianceHeader">Client details</span>
        </ModalHeader>
        <ModalBody>
          <div className="compPrime row justify-content-start">
            <div className="compPrimeDet col-md-5">
              Client Userid-{" "}
              <span className="detailSpan">{history?.user_id}</span>
            </div>
            <div className="compPrimeDet col-md-5">
              Client Email-
              <span className="detailSpan">{history?.email}</span>
            </div>
          </div>
          <div className="compPrime row justify-content-start">
            <div className="compPrimeDet col-md-12">
              <fieldset className="my-fieldset subClientdiv">
                <legend className="login-legend" style={{ fontSize: "1.3rem" }}>
                  Primary Compliances
                </legend>
                <div className="container">
                  <form onSubmit={handleSubmit(primeSubmit)} autocomplete="off">
                    {history?.module_id?.length > 0 ? (
                      <input
                        type="hidden"
                        ref={register}
                        value={history?.module_id}
                        name="id"
                      />
                    ) : (
                      <input
                        type="hidden"
                        ref={register}
                        value={``}
                        name="id"
                      />
                    )}
                    <input
                      type="hidden"
                      ref={register}
                      value={history?.id}
                      name="subclient_id"
                    />
                    <input
                      type="hidden"
                      ref={register}
                      value={history?.id}
                      name="client_id"
                    />
                    <div className="row ml-4">
                      <div className="sub col-md-10 mb-3">
                        <span className="ComplianceHeaderSpan">
                          <span> Compliance : </span>
                          <label
                            className="switch primeCrifSwitch"
                            id="primeCrifSwitch"
                            onChange={(e) => crifperm(e, custId)}
                          >
                            <input
                              type="checkbox"
                              name="hide"
                              checked={stats}
                              id="hide"
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div className="basicFeild row justify-content-around">
                      <div className="sub col-md-10 mb-3">
                        {primeSub?.map((i) => (
                          <span>
                            <input
                              type="checkbox"
                              name={i.name}
                              checked={i.status}
                              // value={i.name}
                              ref={register}
                              id="hide"
                              disabled={!stats}
                              onChange={(e) => handlePrimeSubCategory(e)}
                            ></input>
                            <label
                              htmlFor="hide"
                              style={{
                                margin: "1px",
                                pointerEvents: "none",
                              }}
                            >
                              {i.value}
                            </label>
                          </span>
                        ))}
                      </div>
                      {loading == true ? (
                        <Spinner color="primary" />
                      ) : (
                        <button
                          type="submit"
                          className="customBtn mx-sm-1 mb-2"
                          style={{ height: "50%", width: "5rem" }}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </fieldset>
            </div>
          </div>

          {history != undefined ? (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="row" style={{ width: "30px" }}>
                      S.No
                    </th>
                    <th scope="row" style={{ width: "300px" }}>
                      Sub-Client Name
                    </th>
                    <th scope="row" style={{ width: "560px" }}>
                      E-mail
                    </th>
                    <th scope="row" style={{ width: "150px" }}>
                      Phone-number
                    </th>
                    <th scope="row" style={{ width: "98px" }}>
                      Permissions
                    </th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>

              {secCrif?.map((item, index) => (
                <>
                  <table className="table">
                    <thead></thead>
                    <tbody>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "52px" }}>{index + 1}</td>
                          <td style={{ width: "300px" }}>{item.name}</td>
                          <td style={{ width: "560px" }}>
                            {item.client_email}
                          </td>
                          <td style={{ width: "150px" }}>{item.phone}</td>
                          <td style={{ width: "98px" }}>
                            <div className="subcatCom">
                              {item.status == true ? (
                                <button
                                  className="compbtn"
                                  onClick={() => hidesub(item.id)}
                                  key={item.id}
                                >
                                  <i
                                    class="fa fa-caret-up"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              ) : (
                                <button
                                  className="compbtn"
                                  onClick={() => showsub(item.id)}
                                  key={item.id}
                                >
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </tbody>
                  </table>

                  {item.status === true ? (
                    <fieldset className="my-fieldset subClientdiv">
                      <legend
                        className="login-legend"
                        style={{ fontSize: "1.3rem" }}
                      >
                        Compliance Permissions
                      </legend>
                      <div className="container">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          autocomplete="off"
                        >
                          {item?.module_id?.length > 0 ? (
                            <input
                              type="hidden"
                              ref={register}
                              value={item.module_id}
                              name="id"
                            />
                          ) : (
                            <input
                              type="hidden"
                              ref={register}
                              value={``}
                              name="id"
                            />
                          )}
                          <input
                            type="hidden"
                            ref={register}
                            value={item.id}
                            name="subclient_id"
                          />
                          <input
                            type="hidden"
                            ref={register}
                            value={history.id}
                            name="client_id"
                          />
                          <div className="basicFeild row justify-content-around">
                            <div className="sub col-md-10 mb-3">
                              {allSub?.map((i) => (
                                <span>
                                  <input
                                    type="checkbox"
                                    name={i.name}
                                    checked={i.status}
                                    // value={i.name}
                                    ref={register}
                                    id="hide"
                                    disabled={
                                      primeSub.find(
                                        (sub) => sub.name === i.name
                                      )?.status === true
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => handleSubCategory(e)}
                                  ></input>
                                  <label
                                    htmlFor="hide"
                                    style={{
                                      margin: "1px",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    {i.value}
                                  </label>
                                </span>
                              ))}
                            </div>
                            
                            {secLoad == true ? (
                              <Spinner color="primary" />
                            ) : (
                              <button
                              type="submit"
                              className="customBtn mx-sm-1 mb-2"
                              style={{ height: "50%", width: "5rem" }}
                            >
                              Submit
                            </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </fieldset>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </>
          ) : null}
        </ModalBody>
        {/* <ModalFooter>
          <button className="searchBtn mx-sm-1 mb-2">Cancel</button>
        </ModalFooter> */}
      </Modal>
    </div>
  );
}

export default CustDetails;
